<template>
	<div class="wr_grey_1">
		<v-container class="wr_grey_1" id="value">
			<div class="mt-16">
				<div
					class="text-h5 text-md-h4 font-weight-bold wr_indigo_2--text"
					v-html="
						$environments.environment == 'production'
							? $t('app.home.gain_skills.for_production')
							: $t('app.home.gain_skills')
					"
				></div>
			</div>

			<div v-if="pageLanguage == 'ar'" class="mt-16" align="center">
				<iframe
					:src="videoSrc"
					title="SKILLSX"
					allow="autoplay; fullscreen"
					allowtransparency="true"
					frameborder="0"
					scrolling="no"
					class="wistia_embed"
					name="wistia_embed"
					msallowfullscreen
					width="100%"
					height="100%"
				></iframe>
			</div>

			<!-- Platform -->
			<div id="platform">
				<Title
					:text="$t('app.skillsx_with_style') + ' ' + $t('app.home.platform')"
				></Title>
				<Platform></Platform>
			</div>

			<!-- Journey -->
			<div id="journey">
				<Title :text="$t('app.home.journeys')"></Title>
				<Journeys></Journeys>
			</div>

			<!-- Program For Teachers and Students -->
			<div v-if="pageLanguage != 'en'" id="program">
				<Title :text="$t('app.home.program')"></Title>
				<AboutPrograms></AboutPrograms>
			</div>

			<!-- Get in Touch -->
			<div id="contact_us">
				<Title :text="$t('app.home.listening_you')"></Title>
				<GetInTouch></GetInTouch>
			</div>

			<!-- About -->
			<div id="about">
				<Title :text="$t('app.home.about')"></Title>
				<About></About>
			</div>

			<!-- Book Journey -->
			<div id="join-us">
				<BookJourney></BookJourney>
			</div>
		</v-container>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Title from "@/components/website/Title.vue";
import Platform from "@/components/website/Platform.vue";
import Journeys from "@/components/website/journeys/Index.vue";
import About from "@/components/website/About.vue";
import BookJourney from "@/components/website/BookJourney.vue";
import AboutPrograms from "@/components/website/programs/About.vue";
import GetInTouch from "@/components/website/GetInTouch.vue";

export default {
	name: "Website",

	components: {
		Title,
		Platform,
		Journeys,
		About,
		BookJourney,
		AboutPrograms,
		GetInTouch,
	},

	computed: {
		pageLanguage() {
			return this.$route.query ? this.$route.query.lang : null;
		},

		videoSrc() {
			// if (this.pageLanguage == "he") {
			// 	return "https://fast.wistia.net/embed/iframe/cacwpmgawg?videoFoam=true";
			// }
			if (this.pageLanguage == "ar") {
				return "https://fast.wistia.net/embed/iframe/5x30vhjm1o?videoFoam=true";
			}
		},
	},
};
</script>
